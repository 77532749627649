import logo from './logo.svg';
import './App.css';
import html2pdf from 'html2pdf.js';
import { useState } from 'react';

function App() {
  const [username,setUsername] = useState(null);
  const [ShowInvite,setShowInvite] = useState(false);
  const downloadPdf = () => {
    const element = document.getElementById('mainpage');
    html2pdf()
      .from(element)
      .save();
  };

  const  onSubmit = async (e)=>{
    try {
       setShowInvite(true)
       const response = await fetch('https://api.k4m2a.com/api/User/Invitation?name='+username, {
        method: 'Get',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
    }
  }
  return (
    <>
        {
          !ShowInvite &&
          <div class="maincontainer darkcolor">
              <div class="cntarea">
                  <div class="logobox">
                      <img src="images/logo.jpg" />
                  </div>
                  <h2>જિલ્લા ચૂંટણી અધિકારી અને કલેકટર વડોદરા</h2>
                  <p class="bold">લોકતંત્રમાં તમારી ભાગીદારી સુનિશ્ચિત કરવા સંકલ્પ લઇશું.......</p>
                  <p>અમે ભારતના નાગરીકો, લોકશાહીમાં સંપૂર્ણ વિશ્વાસ રાખીને પ્રતિજ્ઞા લઇએ છીએ કે, અમે, આપણાં દેશની લોકતાંત્રિક પ્રક્રિયાઓની મર્યાદા જાળવીશું અને સ્વતંત્ર, નિષ્પક્ષ અને શાંતિપૂર્ણ ચૂંટણીઓની ગરીમાને જાળવીને, નિર્ભયતાથી, ધર્મ, વર્ગ, જાતિ, સમાજ, ભાષા અથવા અન્ય પ્રલોભનથી પ્રભાવિત થયા સિવાય, દરેક ચૂંટણીમાં અમારા નૈતિક મતાધિકારનો ઉપયોગ કરીશું. </p>
                  <h2>આમંત્રણ</h2>
                  <div class="formbox">
                      <ul>
                          <li>
                              <label>જિલ્લો</label>
                              <input type="text" name="district" id="district" value="વડોદરા" />
                          </li>
                          <li>
                              <label>નામ  </label>
                              <input type="text" name="name" onChange={(e)=>setUsername(e.target.value)} id="name" />
                          </li>
                          <li>
                              <input type="button" disabled={username ? false : true} value="આમંત્રણ માટે સબમિટ કરો" onClick={()=>onSubmit()} class="btn btnsubmit" />
                          </li>
                      </ul>
                  </div>
                  
              </div>
          </div>
        }
        {
            ShowInvite &&
            <>
                <button class="btnmain" onClick={downloadPdf}>Download PDF</button>
                <div id="mainpage" class="mainpage">
                <div class="pagebox">
                    <img src="images/page1.jpg" />
                    <div class="namebox">{username}</div>
                </div>
                <div class="pagebox">
                    <img src="images/page2.jpg" />
                </div>
            </div>
            </>
        }

    </>
    
  );
}

export default App;
